html {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background: #f8f8f8;

  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  display: flex;
}

#root {
  width: 100%;
  margin: 16px 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
}

a {
  color: #FA6400;
}

::placeholder {
  color: #000000;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000;
}

.centerContent {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

.accountBox {
  min-width: 303px;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  padding: 46px;
}

.sectionContent {
  background-color: #eeeeee;
  border-radius: 21px;
  color: #000000;
  text-align: center;
  min-height: 350px;
  width: 468px;
  max-width: 468px;
  margin: 0 auto;
}

.accountContent {
  width: 100%;
  color: #FA6400;
  text-align: left;
  font-size: 18px;
}

.accountTitle {
  padding-top: 40px;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  text-align: left;
}

.siderBarMenu {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 46px;
}

.siderBarMenu button {
  background: none;
  color: #FA6400;
  border: none;
  padding: 0;
  font: inherit;
  font-size: 18px;
  cursor: pointer;
  outline: inherit;
  line-height: 35px;
  text-align: left;
}

.siderBarMenu a {
  font-size: 18px;
  color: #FA6400;
  text-decoration: none;
  line-height: 35px;
}

.siderBarMenu .logo {
  padding: 46px 0;
}

.sectionTitle {
  padding-top: 40px;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  margin: 0;
}

.inputStyle {
  color: #000000;
  display: block;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  margin: auto;
  padding: 15px;
  outline: none;
  width: 100%;
  max-width: 273px;
  margin-bottom: 16px;
  font-size: 14px;
}

.inputStyle:active {
  background-color: #ffffff
}

.inputStyle:focus {
  background-color: #ffffff
}

.buttonStyle {
  font-weight: bold;
  min-width: 273px;
  min-height: 48px;
  border-radius: 40px;
  box-shadow: none;
  color: #ffffff;
  background-color: #FA6400;
  border-width: 0;
  margin-bottom: 16px
}

.accountButton {
  font-weight: bold;
  min-width: 194px;
  min-height: 48px;
  border-radius: 40px;
  box-shadow: none;
  color: #ffffff;
  background-color: #FA6400;
  border-width: 0;
  margin-bottom: 16px
}

.accountButton:hover {
  background-color: #280212;
}

.buttonStyle:hover {
  background-color: #280212;
}

.socialButton {
  min-width: 273px;
  min-height: 48px;
  border-radius: 8px;
  box-shadow: none;
  background-color: #ffffff;
  border-width: 0;
  margin-bottom: 16px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center
}

.socialButton:hover {
  background-color: #EEEEEE;
}

.selectionBoxStyle {
  width: 100%;
  height: 76px;
  background: #FA6400;
  border: 2px solid #E999BF;
  border-radius: 8px;
  margin: auto;
  margin-bottom: 16px;
  display: block;
  font-size: 18px;
  color: #000000
}

.selectionBoxStyle:hover {
  background: #58062A;
}

.errorMessage {
  max-width: 273px;
  min-height: 48px;
  border-radius: 8px;
  box-shadow: none;
  color: #000000;
  background-color: #FF0302;
  border-width: 0;
  margin: auto;
  padding: 15px;
  margin-bottom: 16px
}

@media screen and (max-width: 468px) {
  #root {
    margin: 0;
  }

  .sectionContent {
    height: 100%;
    border-radius: 0;
  }
}

@media screen and (max-width: 665px) {
  .accountBox {
    height: auto;
    margin: 0;
  }
}